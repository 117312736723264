<template>
    <div class="design-template" v-loading="loading">
        <!-- 组件库 -->
        <div class="comps">
            <div class="comps-header">
                <img :src="compsLib" alt="" />
                <span>组件库</span>
            </div>

            <div class="comps-lib">
                <div v-if="compsList.length > 0 && templateBaseInfo.updateType == '2'" class="comps-item-comps-group" @click="compsGroupClick">表单组</div>
                <div class="comps-list">
                    <div
                        class="comps-item"
                        v-for="(item, index) in compsList.filter(
                            (e) => e.type != 'compsGroup'
                        )"
                        :key="index"
                        @click="compItemClick(item)"
                    >
                        {{ item.label }}
                    </div>
                </div>
            </div>
        </div>

        <!-- 预览 -->
        <div class="preview">
            <div class="preview-header">
                <img :src="pc" alt="" />
                <div class="preview-save-btn" @click="formCompsDataSave">保存</div>
            </div>
            <!-- <div style="display: flex;justify-content: center;">
                <div class="preview-title">表单</div>
            </div> -->

            <div class="preview-form common-form" @click="drawItemClick({})">
                <el-form ref="form" label-position="right" label-width="110px" size="medium">
                    <draggable class="drawing-board" :list="drawList" :animation="340" group="componentsGroup">
                        <template v-for="(item, index) in drawList">
                            <!-- 表单组 -->
                            <CompsGroup
                                v-if="item.type == 'compsGroup'"
                                :key="index"
                                :conf="item"
                                :active-data="activeData"
                                @itemClick="drawItemClick"
                                @itemDel="drawItemDel"
                                @chooseValue="handleChooseValue"
                            />
                            <!-- 正常组件 -->
                            <el-col
                                v-else
                                :key="index"
                                v-show="item.show"
                                class="drawing-item"
                                :class="activeData.field == item.field ? 'drawing-item-selected' : ''"
                                :ref="item.field"
                                :sm="24"
                                :md="item.span || 24"
                            >
                                <div class="drawing-item-div" @click.stop="drawItemClick(item)">
                                    <AddressComp v-if="item.type == 'address'" :conf="item" />
                                    <CompItem v-else :conf="item" @chooseValue="handleChooseValue" />
                                    <img v-if="activeData.field == item.field" class="drawing-item-del" :src="del" alt="" @click.stop="drawItemDel(item, index)" />
                                </div>
                            </el-col>
                        </template>
                    </draggable>
                </el-form>
            </div>
        </div>

        <!-- 配置面板 -->
        <div class="panel">
            <ConfigPanel :active-data="activeData" :draw-list="drawList" @relatedComps="handleChooseValue" />
        </div>
    </div>
</template>

<script>
import {
    stuInfoTemplateComps as comps,
    stuInfoTemplateCompsKeysBoolean as keysBoolean,
    stuInfoTemplateCompsKeysObjArr as keysObjArr,
    stuInfoTemplateCompsValObjArr as compsValObjArr,
    stuInfoTemplateCompsChangeOptions as compsChangeOptions
} from 'common-local';
import { v4 as uuidv4 } from "uuid";
import draggable from "vuedraggable";
import CompItem from "./CompItem.vue";
import AddressComp from "./AddressComp.vue";
import CompsGroup from "./CompsGroup.vue";
import ConfigPanel from "./ConfigPanel.vue";

export default {
    name: "DesignTemplate",
    components: {
        draggable,
        CompItem,
        AddressComp,
        CompsGroup,
        ConfigPanel,
    },
    props: [
        "templateBaseInfo"
    ],
    data() {
        return {
            loading: false,
            compsList: [],
            drawList: [],
            activeData: {},
            addressList: [],
        };
    },
    filters: {},
    computed: {
        compsLib() {
            return require("@/assets/images/stuInfoTemplate/comps-lib.png");
        },
        pc() {
            return require("@/assets/images/pc.png");
        },
        del() {
            return require("@/assets/images/stuInfoTemplate/del2.png");
        },
    },
    watch: {},
    created() {
        console.log("[模板设计主组件][created]templateBaseInfo", this.templateBaseInfo)
        // console.log(comps, keysBoolean, keysObjArr)
        if(this.templateBaseInfo) {
            this.getDrawList()
        }
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        // 简单深拷贝
        cloneDeep(data) {
            return JSON.parse(JSON.stringify(data))
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
        // 处理字段及值
        handleKeyVal(obj) {
            // console.log(JSON.stringify([]) + 1, JSON.stringify({}) + 2, JSON.parse('true'))
            let keys = Object.keys(obj)
            // 将丢失的字段加回来
            let oriCompData = comps.find(e => e.type == obj.type)
            if(oriCompData) {
                for(let key in oriCompData) {
                    if(!keys.includes(key)) {
                        obj[key] = oriCompData[key]
                    }
                }
            }
            // 将01转回Boolean
            for(let key of keysBoolean) {
                if(keys.includes(key) && obj[key] !== true && obj[key] !== false) {
                    obj[key] = obj[key] === 1
                    // console.log(key, obj[key])
                }
            }
            // 将JSON字符串转回对象数组
            for(let key of keysObjArr) {
                if(keys.includes(key)) {
                    obj[key] = JSON.parse(obj[key])
                }
            }
            // 如果是可修改选项且有默认值的组件看选项是否还包含默认值
            if(compsChangeOptions.includes(obj.type) && keys.includes('options') && keys.includes('defaultValue')) {
                let optionsVals = []
                if(obj.options.length > 0) {
                    optionsVals = obj.options.map(e => e.value)
                }
                if(compsValObjArr.includes(obj.type)) {
                    if(obj.type == 'select' && !obj.multiple) {
                        if(!optionsVals.includes(obj.defaultValue)) {
                            obj.defaultValue = ''
                        }
                    }else {
                        obj.defaultValue = obj.defaultValue.filter(e => optionsVals.includes(e))
                    }
                }else if(!optionsVals.includes(obj.defaultValue)) {
                    obj.defaultValue = ''
                }
            }
            // console.log(obj)
            return obj
        },
        // 获取模板表单内容
        getDrawList() {
            this.loading = true
            this._fet('/school/schoolArchivesDesign/listByCondition', {
                archivesTypeId: this.templateBaseInfo.id
            }).then(res => {
                console.log("[获取模板表单内容]接口返回：", res)
                this.handleRes(res, () => {
                    let allCompsList = res.data.data.sort((a, b) => { return a.sort - b.sort }); // 根据sort升序
                    for(let comp of allCompsList) {
                        // 处理数据格式
                        comp = this.handleKeyVal(comp)
                        // console.log(comp)

                        // 处理显示与隐藏
                        if(comp.show !== false) {
                            comp.show = true
                        }

                        // 将表单组子组件放入对应表单组中
                        if(comp.type == 'compsGroup') {
                            comp.subComps = allCompsList.filter(e => e.compsGroupId == comp.field)
                        }
                    }
                    // console.log("[获取模板表单内容]allCompsList", allCompsList)
                    let drawList = allCompsList.filter(e => !e.compsGroupId) // 过滤表单组子组件
                    this.drawList = drawList
                    // 处理显示与隐藏
                    for(let comp of this.drawList) {
                        if(comp.relatedComps?.length > 0) {
                            this.handleChooseValue(comp, comp.defaultValue)
                        }
                    }
                    console.log("[处理后模板表单内容]this.drawList", this.drawList)

                    // 获取左侧组件库组件列表数据
                    this.compsList = [...comps];
                })
                this.loading = false
            })
        },
        // 添加组件
        compItemClick(el) {
            let field = 'field' + uuidv4().replaceAll("-", "");
            console.log("[compItemClick]", el.label, field)
            let newProps = {
                field: field,
                show: true,
            }
            let comp = this.cloneDeep(Object.assign({}, el, newProps))

            if(this.activeData.type == 'compsGroup' || this.activeData.compsGroupId) { // 表单组添加组件
                if(this.activeData.type == 'compsGroup') { // 选中表单组添加组件
                    comp.compsGroupId = this.activeData.field
                    if(comp.type == 'compsGroup') {
                        this.$message.error('无法在表单组中添加表单组')
                        return
                    }else {
                        this.activeData.subComps.push(comp)
                    }
                }else if(this.activeData.compsGroupId) { // 选中表单组中的组件添加组件
                    comp.compsGroupId = this.activeData.compsGroupId
                    let subComps = this.drawList.find(e => {
                        // console.log(e.field, this.activeData.compsGroupId)
                        return e.field == this.activeData.compsGroupId
                    })?.subComps
                    // console.log(
                    //     "[选中表单组中的组件]activeData:",this.activeData,
                    //     "subComps:", subComps,
                    //     "drawList:", this.drawList
                    // )
                    let index = subComps?.findIndex(e => e.field == this.activeData.field)
                    if(index > -1) {
                        subComps.splice(index + 1, 0, comp)
                    }
                }
            }else { // 正常添加组件
                if(this.activeData.field) {
                    let index = this.drawList.findIndex(e => e.field == this.activeData.field);
                    this.drawList.splice(index + 1, 0, comp)
                }else {
                    this.drawList.push(comp);
                }
                this.drawItemClick(comp)
            }
        },
        // 添加表单组组件
        compsGroupClick() {
            let el = this.compsList.find(e => e.type == 'compsGroup')
            Object.assign(el, { subComps: [] })
            this.compItemClick(el)
        },
        // 点击组件
        drawItemClick(item) {
            console.log("[drawItemClick]item:", item);
            if(this.activeData?.field == item.field) { // 取消
                this.activeData = {}
            }else { // 选中
                this.activeData = item;
            }
        },
        // 删除组件
        drawItemDel(item, index) {
            console.log("[drawItemDel]item:", item)
            let compsList = this.drawList
            if(item.compsGroupId) { // 是表单组子组件
                compsList = this.drawList.find(e => e.field == item.compsGroupId)?.subComps
            }
            if(!compsList) {
                console.error("[drawItemDel]表单组子组件列表未找到")
            }

            let idx = compsList.findIndex(e => e.field == item.field)
            // console.log("[drawItemDel]idx", idx);
            if(idx > -1) {
                // 检查是否是被关联组件
                let list = this.drawList
                if(item.compsGroupId) {
                    list = list.find(e => e.field == item.compsGroupId).subComps
                }
                let allRelatedComps = this.getAllRelatedComps(list)?.map(e => e.field)
                if(allRelatedComps.includes(item.field)) {
                    return this.$message.error('此组件被其他组件关联，请先解除关联')
                }

                compsList.splice(idx, 1)
                this.activeData = {}

                // 删除的组件如果有关联组件则将关联组件全部显示
                if(item.relatedComps?.length > 0) {
                    let fields = item.relatedComps.map(e => e.field)
                    for(let field of fields) {
                        let comp = compsList.find(e => e.field == field)
                        if(comp) {
                            comp.show = true
                        }
                    }
                }
            }
        },
        // 获取被关联的全部组件
        getAllRelatedComps(list) {
            if(!list) {
                list = this.drawList
            }
            return list.filter(e => e.relatedComps?.length > 0)?.map(e => e.relatedComps)?.flat(Infinity)
        },
        handleChooseValue(comp, val) {
            console.log("[handleChooseValue]", comp, val)
            if(['select', 'radio', 'checkbox'].includes(comp.type)) { // 下拉选择、单选框组、多选框组
                if(comp.relatedComps.length > 0) {
                    let selVal = [].concat(val)
                    console.log("[handleChooseValue]selVal:", selVal, "relatedComps:", comp.relatedComps)
                    // 要显示的关联组件
                    let showRelatedComps = comp.relatedComps.filter(e => selVal.includes(e.opt))?.map(e => e.field)
                    // 要隐藏的关联组件，要隐藏的要过滤掉要显示的，因为多选且多个选项关联同一个组件时，只要有一个选项选中了，那个被关联组件就要显示出来
                    let hideRelatedComps = comp.relatedComps.filter(e => !showRelatedComps.includes(e.field))?.filter(e => !selVal.includes(e.opt))?.map(e => e.field)
                    let compsList = this.drawList
                    if(comp.compsGroupId) { // 是表单组子组件
                        compsList = this.drawList.find(e => e.field == comp.compsGroupId)?.subComps
                    }
                    console.log(
                        "[handleChooseValue]showRelatedComps:", showRelatedComps,
                        "hideRelatedComps:", hideRelatedComps,
                        "compsList:", compsList
                    )
                    if(showRelatedComps.length > 0 && compsList?.length > 0) {
                        for(let field of showRelatedComps) {
                            let showRelatedComp = compsList.find(e => e.field == field)
                            if(showRelatedComp) {
                                showRelatedComp.show = true
                            }
                        }
                    }
                    if(hideRelatedComps.length > 0 && compsList?.length > 0) {
                        for(let field of hideRelatedComps) {
                            let hideRelatedComp = compsList.find(e => e.field == field)
                            if(hideRelatedComp) {
                                hideRelatedComp.show = false
                            }
                        }
                    }
                }
                // 检查是否有已隐藏但不被任何组件关联的组件，将其显示出来
                let drawList = this.drawList
                if(comp.compsGroupId) { // 是表单组子组件
                    drawList = drawList.find(e => e.field == comp.compsGroupId).subComps
                }
                let hideComps = drawList.filter(e => e.show === false)
                console.log("[handleChooseValue]hideComps.filed:", hideComps)
                let allRelatedComps = this.getAllRelatedComps(drawList)?.map(e => e.field)
                console.log("[handleChooseValue]allRelatedComps:", allRelatedComps)
                for(let hideComp of hideComps) {
                    // console.log(hideComp.field, allRelatedComps.includes(hideComp.field))
                    if(!allRelatedComps.includes(hideComp.field)) {
                        hideComp.show = true
                        // console.log(hideComp.show)
                    }
                }
            }
        },
        treeSetVal(data, subKey) {
            for(let i in data) {
                i = parseInt(i)
                let item = data[i]
                item.sort = i + 1
                item.archivesTypeId = this.templateBaseInfo.id
                // 将对象数组转为JSON字符串
                for(let key in item) {
                    if(keysObjArr.includes(key)) {
                        item[key] = JSON.stringify(item[key])
                    }
                }
                if(item[subKey]) {
                    item[subKey] = this.treeSetVal(item[subKey], subKey)
                }
            }
            return data
        },
        // 模板表单内容保存
        formCompsDataSave() {
            if(this.drawList.length == 0) {
                this.$message.error("请添加模板内容")
                return
            }
            // 检查表单组是否有子组件
            let compsGroupList = this.drawList.filter(e => e.type == "compsGroup")
            let noSubComps = compsGroupList.findIndex(e => !e.subComps || e.subComps?.length == 0)
            if(noSubComps > -1) {
                this.$message.error("请添加表单组内容")
                return
            }

            this.loading = true
            let drawList = this.cloneDeep(this.drawList)
            // 处理树形数据
            drawList = this.treeSetVal(drawList, 'subComps')
            // 将表单组中的子组件拿出来
            let subCompsList = drawList.filter(e => e.subComps?.length > 0).map(e => e.subComps)?.flat()
            console.log("[formCompsDataSave]subCompsList:", subCompsList)
            // 删除已无用的字段
            for(let comp of drawList) {
                delete comp.show
                if(comp.type == 'compsGroup') {
                    delete comp.subComps
                }
            }
            // 二维数组转一维数组
            drawList = drawList.concat(subCompsList)
            console.log("[formCompsDataSave]drawList:", drawList)
            this._fet("/school/schoolArchivesDesign/saveOrUpdateAll", drawList, { isArrParams: true}).then((res) => {
                this.handleRes(res, () => {
                    this.$message.success("保存成功")
                    this.getDrawList()
                })
                this.loading = false
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.design-template {
    max-width: 1524px;
    overflow-x: auto;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: row;
}

// 公共
// 公共-单图片上传
::v-deep .avatar-uploader {
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}

// 左侧组件库
.comps {
    width: 244px;
    height: 100%;
    background: #ffffff;
    border-radius: 8px 0px 0px 8px;
    padding: 18px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
// 组件库-标题
.comps-header {
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        width: 18px;
        height: 18px;
    }

    span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
        margin-left: 8px;
    }
}
// 组件库
.comps-lib {
    flex: 1;
    overflow-y: auto;
}
// 组件库-组件列表
@mixin comps-item($width, $margin-top) {
    height: 32px;
    background: #ffffff;
    border: 1px solid #e1e3e6;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6d6f73;
    line-height: 32px;
    text-align: center;
    width: $width;
    margin-top: $margin-top;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
}
.comps-item-comps-group {
    @include comps-item(100%, 16px);
}
.comps-item {
    @include comps-item(calc(50% - 6px), 12px);
}
.comps-list .comps-item:nth-child(2n) {
    margin-left: 12px;
}

// 中间表单预览
.preview {
    flex: 1;
    height: 100%;
    background: #ffffff;
    margin-left: 10px;
}
// 预览-头部
.preview-header {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;

    img {
        widows: 20px;
        height: 18px;
    }

    .preview-save-btn {
        width: 80px;
        height: 32px;
        background: #3C7FFF;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
    }
}
// 预览-标题
// .preview-title {
//     height: 100%;
//     font-family: 华文楷体;
//     font-size: 24px;
//     text-align: center;
//     padding-top: 3px;
//     margin-top: 20px;
//     margin-bottom: 20px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
// 预览-表单
.preview-form {
    margin: 0 auto;
    overflow: hidden;
    height: calc(100% - 53px);
    box-sizing: border-box;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 36px 0;
}
// 预览-表单-组件
.drawing-item {
    position: relative;
    cursor: move;

    .drawing-item-div {
        box-sizing: border-box;
        // padding-bottom: 18px;
        padding: 22px 30px;

        ::v-deep .el-form-item {
            margin-bottom: 0;

            .el-form-item__label {
                word-wrap: break-word;
            }
        }

        // 预览-组件-字段说明
        ::v-deep .explainText {
            // position: absolute;
            // bottom: -25px;
            // left: 0px;
            font-size: 12px;
            color: #dd6161;
        }

        // 预览-组件-分割线
        .divider {
            position: relative;
            border-top: 1px solid #dcdfe6;

            ::v-deep .divider-title {
                background: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 0 20px;
                font-size: 16px;
            }
        }
    }
}
.drawing-item-selected {
    box-shadow: 0px 5px 20px 0px #f0f2f5;
    border-left: 4px solid #3C7FFF;
}
.drawing-item-del {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 16px;
    right: 13px;
    cursor: pointer;
}

// 右侧配置面板
.panel {
    width: 320px;
    height: 100%;
    background: #ffffff;
    border-radius: 0px 8px 8px 0px;
    margin-left: 10px;
}
</style>
